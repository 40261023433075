import { GlobalStyle } from '@moonpig/launchpad-components'
import { theme } from '@moonpig/launchpad-theme'
import { ThemeProvider } from '@moonpig/launchpad-utils'
import type { AppProps } from 'next/app'
import React, { FC } from 'react'

const App: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Component {...pageProps} />
    </ThemeProvider>
  )
}

export default App
